/*-----------------------------------*/
/*--- IMPORT STYLES FOR FASTPAGES ---*/
@import "minima/fastpages-styles";   



/*-----------------------------------*/
/*----- ADD YOUR STYLES BELOW -------*/

// If you want to turn off dark background for syntax highlighting, comment or delete the below line.
@import "minima/fastpages-dracula-highlight"; 

$site-title-color: #ffffff;

.site-header {
    background-color: rgba(151,151,151,0.9);
    border-top: none;

}

.site-title {
    &:before{
        background: url(/images/logo.png) CENTER CENTER NO-REPEAT;
        content: "";
        width: 40px;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
    }

    &, &:visited {
        color: $site-title-color;
    }
}
.site-nav{
    .page-link {
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        text-align:center;
        text-shadow: 1px 1px 1px #222222;
        padding: 0 5px 5px 5px; 
    }
}
